@import "../../style.scss";

.admin-edu {
    @include themify ($themes) {

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: themed("textColor");

        .container {
            padding: 20px;
            width: 90%;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            color: themed("textColor");
            transition: .5s;

            span {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }

            .education {
                display: flex;
                justify-content: center;
                gap: 10px;
                @include mobile{
                    flex-direction: column;
                }
                .one-education {
                    flex: 1;
                    
                    padding: 10px;
                    .one-education-info {
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                        // flex-direction: column;
                        gap: 10px;
                        // background-color: themed("bgTitle");
                        // background-color: red;
                        span {
                            font-size: 14px;
                            font-weight: 500;
                        }

                        .info {
                            // display: flex;
                            // align-items: center;
                            // justify-content: space-between;
                            padding: 5px 0;
                            color: themed("textColorSoft");

                            label {
                                font-size: 12px;
                                font-weight: 450;
                            }
                        }
                    }

                    p {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
    }


}