@import "../../style.scss";

.footer {
    @include themify ($themes) {

        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: themed("textColor");

        .container {
            padding: 20px 0px;
            margin-bottom: 10px;
            width: 100%;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            color: themed("textColor");
            transition: .5s;
            span {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
                color: themed("textColorSoft");
            }

            
        }
    }


}