@import "../../style.scss";

.admin-social {
    @include themify ($themes) {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: themed("textColor");
        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            width: 50%;
            display: flex;
            gap: 30px;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            color: themed("textColor");
            transition: .5s;
            @include mobile{
                width: 90%;
                flex-direction: column;
            }
            span {
                color: themed('logo');
                font-weight: 500;
                @include mobile{
                    font-size: 20px;
                }
            }
            input {
                padding: 5px 10px;
                width: 50%;
                @include mobile{
                    width: 90%;
                }
            }
        
        }
    }
}