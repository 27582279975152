@import "../../style.scss";

.admin-pro {
    @include themify ($themes) {

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: themed("textColor");

        .container {
            padding: 20px;
            width: 90%;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            color: themed("textColor");
            transition: .5s;
            span {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }

            .projects {
                display: flex;
                gap: 30px;
                // width: 500px;
                @include mobile {
                    flex-direction: column;
                }
                
                .project {
                    // width: 100%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    // position: relative;

                    span {
                        font-size: 14px;
                        font-weight: 450;
                    }

                    img {
                        width: 95%;
                    }

                    .project-info {
                        // visibility: visible;
                        // display: flex;
                        // flex-direction: column;
                        // justify-content: center;
                        // align-items: center;
                        // gap: 10px;
                        color: themed("textColor");
                        // // width: 90%;
                        font-size: 14px;
                        // padding: 10px 0;
                        background-color: themed("bgSoft");
                        // // position: absolute;
                        // // top: 0.5;
                        // z-index: 99;
                        // transition: .2s;
                        // opacity: 100%;
                        

                        @include tablet {
                            opacity: 93%;
                            max-height: 110px;
                            top: 35px;
                            gap: 3px;
                        }

                        @include mobile {
                            opacity: 85%;
                            top: 40px;
                            max-height: 170px;
                            gap: 5px;
                        }
                        

                        p {
                            font-weight: 450;
                            font-size: 14px;
                            max-height: 70px;
                            overflow-y: scroll;
                            
                            

                            &::-webkit-scrollbar {
                                width: 8px;
                            }
                            @include mobile {
                                &::-webkit-scrollbar {
                                    width: 3px;
                                }
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: themed("bgSoft");
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: themed("border");
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: themed('textColor');
                            }
                        }

                        label {
                            font-weight: 450;
                            font-size: 12px;
                            color: themed("textColorSoft");
                            
                        }
                        input {
                            // width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid themed("border");
                            background-color: themed("bg");
                            color: themed("textColor");
                            overflow: scroll;
                        }

                        button {
                            border: none;
                            border-radius: 25px;
                            color: rgb(0, 110, 183);
                            padding: 5px 15px;
                            margin: 2px 0;
                            font-weight: 450;
                            @include tablet {
                                padding: 0 10px; 
                            }
                            // background-color: themed("border");
                            a {
                                text-decoration: none;
                                font-size: 12px;
                            }
                        }
                    }

                    img:hover+.project-info {
                        opacity: 85%;
                    }

                    .project-info:hover {
                        opacity: 85%;
                    }
                }
            }
        }
    }
}