@import "../style.scss";

.loader {
    @include themify ($themes) {
        width: 23px;
        height: 23px;
        border: 5px solid themed("textColoer");
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}