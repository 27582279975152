@import "../../style.scss";
.admin {
    @include themify ($themes) {

        background-color: themed("bgSoft");
        transition: .5s;
        box-sizing: border-box;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid themed("border");
            background-color: themed("bg");
            color: themed("textColor");
        }
        button {
            padding: 5px 10px;
            width: 80px;
            height: 35px;
        }
    }
}