@import "../../style.scss";

.contact {
    @include themify ($themes) {

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: themed("textColor");

        .container {
            padding: 20px;
            width: 90%;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            color: themed("textColor");
            box-sizing: border-box;
            position: relative;
            transition: .5s;

            span {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }

            iframe {
                width: 100%;
                height: 400px;

            }

            .form-contact {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 300px;
                border-radius: 15px;
                overflow: hidden;
                @include mobile {
                    width: 80%;
                }
                form {
                    height: 300px;
                    transition: .5s;
                    // width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    align-items: center;
                    gap: 20px;
                    background-color: themed("bg");
                    // border: 1px solid themed("border");
                    p{
                        font-size: 12px;
                        color: themed("textColorSoft");
                    }
                    label{
                        font-size: 10px;
                        padding: 0;
                        margin: 0;
                    }
                    input {
                        width: 80%;
                        border: none;
                        border-bottom: 1px solid lightgray;
                        padding: 5px 10px;
                        margin-top: 0px;
                        background-color: themed("bg");
                        color: themed("textColor");
                        transition: .5s;
                    }

                    button {
                        width: 40%;
                        padding: 10px;
                        border: none;
                        background-color: #938eef;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        // margin: 5px 0;
                    }
                }
            }



        }
    }


}