@import "../../style.scss";

.about {
    @include themify ($themes) {

        padding: 10px;
        display: flex;
        justify-content: center;
        color: themed("textColor");

        .container {
            padding: 20px;
            width: 90%;
            display: flex;
            gap: 30px;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            transition: .5s;
            color: themed("textColor");

            span {
                font-size: 20px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                padding-bottom: 10px;
            }

            @include mobile {
                flex-direction: column;
            }

            .left {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                width: 100%;

                .form-about {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    flex-direction: column;

                    textarea {
                        width: 500px !important;
                        border: none;
                        min-height: 150px;
                        border-bottom: 1px solid themed("border");
                        background-color: themed("bg");
                        color: themed("textColor");
                        overflow-y: scroll;
                        &::-webkit-scrollbar {
                            width: 4px;
                        }
                        @include mobile {
                            &::-webkit-scrollbar {
                                width: 3px;
                            }
                            width: 270px !important;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: themed("bgSoft");
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: themed("border");
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: themed('textColor');
                        }
                    }

                    button {
                        padding: 5px 10px;
                        width: 20%;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                    }
                }

            }

            .right {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;

                button {
                    padding: 5px 10px;
                    width: 20%;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                }

                .items {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    .labels-title {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        gap: 10px;

                        label {
                            font-size: 12px;
                            font-weight: 500;
                            color: themed("textColorSoft");
                        }
                    }

                    .labels-info {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        gap: 10px;

                        label {
                            font-size: 12px;
                            font-weight: 500;
                            // padding: 10px;
                        }

                        input {
                            
                        }width: 100%;
                            border: none;
                            border-bottom: 1px solid themed("border");
                            background-color: themed("bg");
                            color: themed("textColor");
                    }
                }





            }
        }
    }
}