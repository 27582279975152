@import "../../style.scss";

.admin-exp {
    @include themify ($themes) {

        padding: 10px;
        display: flex;
        justify-content: center;
        color: themed("textColor");
        .container {
            padding: 20px;
            width: 90%;
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 5px;
            background-color: themed("bg");
            color: themed("textColor");
            transition: .5s;
            span {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }
            .experiences{
                display: flex;
                flex-wrap: wrap;
                @include mobile{
                    flex-direction: column;
                }
                .experience{
                    flex: 1;
                    padding: 10px;
                    display: flex;
                    input {
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid themed("border");
                        background-color: themed("bg");
                        color: themed("textColor");
                    }
                    button{
                        // font-size: 12px;
                    }
                    .w3-light-grey{
                        .w3-grey{
                            color: themed("textColor") !important;
                            background-color: themed('border') !important;
                        }
                    }
                    label{
                        font-size: 14px;
                    }
                }
            }
        }
    }


}